import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// components
import Widget from "../../components/Widget/Widget";
import ApexDonutSigType from "./components/ApexDonutSigType";
import ApexDonutKCType from "./components/ApexDonutKCType";
import ApexSigLineChart from "./components/ApexSigLineChart";
import ApexDonutMitreType from "./components/ApexDonutMitreType";
import ApexDonutAdjSevType from "./components/ApexDonutAdjSevType";

import PageTitle from "../../components/PageTitle/PageTitle";

export default function Charts(props) {
  return (
    <>
      <PageTitle title="Dashboard"
       /> <i>*Stats shows for latest 20 alerts per customer</i>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Widget title="Signatures stats  per Day" noBodyPadding upperTitle>
          <ApexSigLineChart />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Mitre Stats" upperTitle noBodyPadding>
            <ApexDonutMitreType />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Severity Stats" upperTitle noBodyPadding>
            <ApexDonutAdjSevType />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Signature Stats" upperTitle noBodyPadding>
            <ApexDonutSigType />
          </Widget>
        </Grid>
        <Grid item xs={12} md={6}>
          <Widget title="Killchain Stats" upperTitle noBodyPadding>
            <ApexDonutKCType />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
