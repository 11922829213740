import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";


// components
import PageTitle from "../../components/PageTitle";
import Button from '@material-ui/core/Button';
import {  useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import {get_access_txt, fetcher} from '../../utils/Utils';
import { useMsal} from "@azure/msal-react";

const columns = [
{
  name: "customer",
  label: "Customer",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "identifiedcustomernetworkcount",
  label: "Identified Customer Network Count",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "unknownorgrisk",
  label: "Unknown Risk",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "vulnscannetworkcount",
  label: "Vuln Scan Network Count",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "unknowndetails",
  label: "Unknown Details ",
  options: {
   filter: true,
   sort: true,
  }
},

];

const styledeco = {
    //display: 'block',
    width: '20%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

export default function Tables() {
  let postData = {};
  const [customercontext, setCustommerContext] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal()
  let access_txt = get_access_txt(accounts, 'Customer');
  let access_txt_arr  = access_txt.split(",");
  const { handleSubmit } = useForm();
  const onSubmit = (subdata: any) => fetchData(subdata);

  const fetchData = async (subdata) => {
    var selected_customer = document.getElementById("customerValue").options[document.getElementById("customerValue").selectedIndex].value; 
    try {
        postData = {"customer": selected_customer}
    } catch(error) {
        postData = {"customer": "j-auto-dev-forti"}
    }
    setIsLoading(true)
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic'},
        body: JSON.stringify(postData)
    };
    let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/customercontext"
    let masterdata = [];
    //setIsLoading(true)
    if (selected_customer === ''){ // means show all
    for (let i=0; i < access_txt_arr.length; i++) {
      postData = {"customer": access_txt_arr[i]};
      requestOptions['body'] = JSON.stringify(postData)
      let data = await fetcher(url, requestOptions).then(data => {
      masterdata.push(data);
      if (access_txt_arr.length -1  === i){
          setCustommerContext(masterdata);
          setIsLoading(false)
      }
      });
    }
    } else { // means show selected
    fetch(url,
         requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        return data 
      })
      .then(data => {
        setIsLoading(false)
        setCustommerContext([data])
      })
      .catch(error => {
            console.error('There was an error!', error);
            setCustommerContext([]);
            
  })
  }
  }
  useEffect(() => {
    fetchData()
  }, [])
return (
    <>
      <PageTitle title="Customer Context View" />
      <form>
      <select style={styledeco} name="customerValue" id="customerValue">
      <option value="" selected>--Select Customer--</option>
    { access_txt_arr ?
    (access_txt_arr.map((dd) => {
             return <option value={dd} >{dd}</option>

    })) : null
    })
    </select>
      &nbsp;&nbsp;
      <Button onClick={handleSubmit(onSubmit)} variant="contained">Search</Button>
      <br></br>
    </form> 
     <div></div> 
     <div></div> 
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <React.Fragment>
          {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
          <MUIDataTable
            title="Customer Context Data"
            data={customercontext}
            columns={columns}
            options={{
              selectableRows: false,
              filter: false,
              rowsPerPageOptions: false
            }}
          />
    )}
    </React.Fragment>
        </Grid>
      </Grid>
    </>
  );
}
