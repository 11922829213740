export const get_access_txt = (accounts, type='Customer') => {
        var access_txt = '';
        try {
            var tmp_txt = "";
            var roles = accounts[0]?.idTokenClaims.roles;
            for (let i=0; i < roles.length; i++) {
                let roles_coming = roles[i]
                if (roles_coming.includes("#")){
                    roles_coming = roles_coming.split("#")
                    roles_coming = roles_coming[1]
                }
                let role_split = roles_coming.split("@")
                if (type.toLowerCase() === role_split[0].toLowerCase())
                    tmp_txt = tmp_txt+role_split[1]+","
            }
            return tmp_txt.substring(0, tmp_txt.length - 1);;
        }catch (error) {
            console.error(error);
            return access_txt;
        }
    }

export const fetcherData = async (url: string, requestOptions: object, dataOptions: object) => {
//export const fetcher = (url: string, requestOptions: object, dataOptions: object) => {
    //if (!dataOptions.hasOwnProperty('customer')){
    //    let cust_arr = dataOptions['customer'].split(",")
    //    console.log(cust_arr)
    //}
    return await fetch(url, requestOptions).then((res) => res.json())
}

export async function fetcher(url: string, requestOptions: object) {
        let data = [];
        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                let message = new Error(`HTTP error! status: ${response.status}`);
                return {'message': message}
            }
            data = await response.json();
        } catch (error) {
            data = []
        }
        return data;
}
