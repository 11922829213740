import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiTwitter as TwitterIcon,
  mdiLinkedin as mdiLinkedinIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import AssetContext from "../../pages/assetcontext";
import AssetContextDetail from "../../pages/assetcontextdetail";
import CustomerContext from "../../pages/customercontext";
import ThreatContextHistory from "../../pages/threatcontexthistory";
import ThreatContextHistoryDetail from "../../pages/threatcontexthistorydetail";
import ContactDetail from "../../pages/contact";

// context
import { useLayoutState } from "../../context/LayoutContext";

// msal auth
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { PublicClientApplication, AccountInfo, InteractionType } from "@azure/msal-browser"
import { msalConfig } from "../../utils/msalConfig"

export const msalInstance = new PublicClientApplication(msalConfig)

function Layout(props) {
  var classes = useStyles();
  console.log("msal instance...");
  console.log(msalInstance);
  const setActiveAccount = (account: AccountInfo) => {
    msalInstance.setActiveAccount(account)
  }

  msalInstance.handleRedirectPromise().then((result) => {
  if (result && result.account) {
    setActiveAccount(result.account)
  }
  })

  const accounts = msalInstance.getAllAccounts()
  if (accounts && accounts.length > 0) {
    setActiveAccount(accounts[0])
  }

  // global
  var layoutState = useLayoutState();

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      >
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/assetcontext" component={AssetContext} />
              <Route path="/app/assetcontextdetail" component={AssetContextDetail} />
              <Route path="/app/customercontext" component={CustomerContext} />
              <Route path="/app/threatcontexthistory" component={ThreatContextHistory} />
              <Route path="/app/threatcontexthistorydetail" component={ThreatContextHistoryDetail} />
              <Route path="/app/contact" component={ContactDetail} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://www.security.ntt/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  <p>Copyright &copy; NTT Security Holdings 2023</p> 
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.linkedin.com/company/ntt-security-holding'}
                  target={'_blank'}
                >
                  <IconButton aria-label="linkedin">
                    <Icon
                      path={mdiLinkedinIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://mobile.twitter.com/nttsecurity'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
    </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default withRouter(Layout);
