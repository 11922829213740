import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Moment from 'react-moment';
import CircularProgress from '@mui/material/CircularProgress';

// components
import PageTitle from "../../components/PageTitle";

import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';

import {get_access_txt, fetcher} from '../../utils/Utils';
import { useMsal} from "@azure/msal-react";

const columns = [
{
  name: "customer",
  label: "Customer",
  options: {
   filter: false,
   sort: false,
  }
},
{
  name: "signature",
  label: "Signature",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "reliable",
  label: "Send to Contrive",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (val) => {
            return val === true ? "True" : "False";
  }
  }
},
{
  name: "src",
  label: "Src Ip",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "dst",
  label: "Dest Ip",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "adjusted_confidence",
  label: "Adj. Confidence",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "adjusted_severity",
  label: "Adj. Severity",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "suppressed",
  label: "Suppressed",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (val) => {
            return val === true ? "True" : "False";
  }
  }
},
{
  name: "suppressed_reason",
  label: "Suppressed Reason",
  options: {
   filter: true,
   sort: true,
  }
},
{
  name: "timestamp",
  label: "Timestamp",
  options: {
   filter: true,
   sort: true,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Moment format="YYYY-MM-DD hh:mm:ss">{value}</Moment>
          </div>
        )
  }
},
{
  name: "timestamp",
  label: "View",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/app/threatcontexthistorydetail?c="+tableMeta.rowData[0]+"&t="+value}><VisibilityIcon/></a>  
          </div>
        )
  }
}
];

const styledeco = {
    //display: 'block',
    width: '20%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

export default function Tables() {
  let postData = {};
  const [customerhistorycontexts, setCustommerHistoryContexts] = useState([])
  const [customerhistorycontext, setCustommerHistoryContext] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal()
  let access_txt = get_access_txt(accounts, 'Customer');
  let access_txt_arr  = access_txt.split(",");
  const { handleSubmit } = useForm();
  //const onSubmit = (data: any) => console.log(data.customerValue);
  const onSubmit = (subdata: any) => fetchData(subdata);

  const fetchData = async (subdata) => {
    var selected_customer = document.getElementById("customerValue").options[document.getElementById("customerValue").selectedIndex].value;
    try { 
        postData = {"customer": selected_customer}
    } catch(error) {
        postData = {"customer": "j-auto-dev-forti"}
    }
    setIsLoading(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic'},
        body: JSON.stringify(postData)
    };
    let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/threatcontext/history"
    let masterdata = [];
    if (selected_customer === ''){ // means show all
    for (let i=0; i < access_txt_arr.length; i++) {
      postData = {"customer": access_txt_arr[i]};
      requestOptions['body'] = JSON.stringify(postData)
      let data = await fetcher(url, requestOptions).then(data => {
      if (data && data.hasOwnProperty('history')){
      for (let j=0; j < data['history'].length; j++) {
      masterdata.push(data['history'][j]);
      }
      if (access_txt_arr.length -1  === i){
          setIsLoading(false);
          setCustommerHistoryContext(masterdata);
          setCustommerHistoryContexts([data])
      }
      } else{
      
      }
      });
    }
    } else { // means show selected
    fetch(url,
         requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        return data 
      })
      .then(data => {
        setIsLoading(false)
        setCustommerHistoryContext(data['history'])
        setCustommerHistoryContexts([data])
      })
      .catch(error => {
            console.error('There was an error!', error);
            setCustommerHistoryContexts([]);
            setCustommerHistoryContext([]);
            
  })
  }
  }
  useEffect(() => {
    fetchData()
  }, [])
return (
    <>
      <PageTitle title="Threat Context History View" />
      <form>
      <select style={styledeco} name="customerValue" id="customerValue">
      <option value="" selected>--Select Customer--</option>
    { access_txt_arr ?
    (access_txt_arr.map((dd) => {
             return <option value={dd} >{dd}</option>

    })) : null
    })
    </select>
      &nbsp;&nbsp;
      <Button onClick={handleSubmit(onSubmit)} variant="contained">Search</Button>
    </form> 
     <div></div> 
     <div></div> 
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <React.Fragment>
          {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
          <MUIDataTable
            title="Threat Context History Data"
            data={customerhistorycontext}
            columns={columns}
            options={{
              selectableRows: false,
              filter: false,
              rowsPerPageOptions: false
            }}
          />
    )}
    </React.Fragment>
        </Grid>
      </Grid>
    </>
  );
}
