import React from "react";

export default function Tables() {
return (
          <React.Fragment>
         <div><h1>Contact</h1>
            If you have questions or feedback, contact us!
            <br/><br/>For things regarding VTCache, contact <a href="mailto:himanshu.sinha@global.ntt">Himanshu Sinha.</a>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
         </div> 
    )}
    </React.Fragment>
  );

}
