import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
} from "@material-ui/core";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// msal auth
import { useMsal} from "@azure/msal-react"

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

function get_access_txt(accounts, type='Platform'){
        var access_txt = '';
        try {
            var tmp_txt = "";
            var roles = accounts[0]?.idTokenClaims.roles;
            for (let i=0; i < roles.length; i++) {
                let roles_coming = roles[i]
                if (roles_coming.includes("#")){
                    roles_coming = roles_coming.split("#")
                    roles_coming = roles_coming[1]
                }
                let role_split = roles_coming.split("@")
                if (type.toLowerCase() === role_split[0].toLowerCase())
                    tmp_txt = tmp_txt+role_split[1]+","
            }
            return tmp_txt.substring(0, tmp_txt.length - 1);
        }catch (error) {
            console.error(error);
            return access_txt;
        }
    }

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var [profileMenu, setProfileMenu] = useState(null);
  const { accounts, instance } = useMsal()
  const handleLogoutRedirect = () => {
      instance.logoutRedirect().catch((error) => console.log(error));
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          BOON 
        </Typography>
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          className={classes.headerMenuButton}
        >
        </IconButton>
        {accounts[0]?.name|| 'Unknown'}<IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="notifications-menu"
          className={classes.headerMenu}
          disableAutoFocusItem
        >
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h8" weight="bold">
              Name: {accounts[0]?.name || 'Unknown'}
            </Typography>
            <Typography variant="h8" weight="bold">
              Email: {accounts[0]?.username || 'Unknown'}
            </Typography>
            { get_access_txt(accounts, 'Customer') ?
            <Typography variant="h8" weight="bold">
              Customer Access: {get_access_txt(accounts, 'Customer')}
            </Typography> : ''
            }
          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={(e) => handleLogoutRedirect(e)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
