import React , { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

import {get_access_txt, fetcher} from '../../../utils/Utils';
import { useMsal} from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';

function myDateParser(dateString) {
    var comps = dateString.split("-");
    return new Date(comps[0], comps[1], comps[2]);
}

export default function ApexSigLineChart() {
  var theme = useTheme();
  const [consumptionpd, setConsumptionPerDay] = useState([])
  const [consumptionpdt, setConsumptionDt] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal()
  let access_txt = get_access_txt(accounts, 'Customer');
  let access_txt_arr  = access_txt.split(",");
  let api_url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/threatcontext/history" 
  let postData = {"customer": access_txt}
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic' },
        body: JSON.stringify(postData)
    };
  const fetchData = async () => {
    setIsLoading(true);
    let masterdata = [];
    let data = [];
    for (let i=0; i < access_txt_arr.length; i++) {
      postData = {"customer": access_txt_arr[i]};
      requestOptions['body'] = JSON.stringify(postData)
      let data = await fetcher(api_url, requestOptions);
      if (data && data.hasOwnProperty('history')){
      for (let j=0; j < data['history'].length; j++) {
          masterdata.push(data['history'][j]);
      }
      } else{
      }
      }
        data = masterdata;
        const countsBySig = [];
        data.forEach(({ signature }) => {
        countsBySig[signature] = (countsBySig[signature] || 0) + 1;
        //countsBySig[signature] = [];
        });
        const countsByDate = [];
        let dateFormat = "";
        data.forEach(({ timestamp }) => {
        const date = new Date(timestamp);
        var year = date.getFullYear();
        var month = ("0" + (date.getMonth() + 1)).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);

        dateFormat = year+"-"+month+"-"+day;
        countsByDate[dateFormat] = (countsByDate[dateFormat] || 0) + 1;
        });
        var uniqplotdatesig = []
        for (const [key, value] of Object.entries(data)) {
            const date = new Date(value['timestamp']);
            var year = date.getFullYear();
            var month = ("0" + (date.getMonth() + 1)).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);
            dateFormat = year+"-"+month+"-"+day;
            var str = value['signature']+"##"+dateFormat
            uniqplotdatesig.push({'signature': value['signature'], 'date': dateFormat, 'datetime': date,  'data': []})
        }

        const UniqueArray = Object.entries(countsBySig)
        .map(([signature, count]) => ({ signature, count }))
        .sort((a, b) => b.count - a.count);

        const UniqueDateArray = Object.entries(countsByDate)
        .map(([date, count]) => ({ date, count }));
        var sigseries = [];
        var siglabel = [];
        var datelabel = [];
        for (var key in UniqueArray) {
            siglabel.push(UniqueArray[key]['signature'])
            sigseries.push(UniqueArray[key]['count'])
        }
        for (var key in UniqueDateArray) {
            datelabel.push(UniqueDateArray[key]['date'])
        }
        datelabel = datelabel.reverse();
        var uniqplot = []
        for (var key in UniqueArray) {
            uniqplot.push({'name': UniqueArray[key]['signature'], 'data':[]})
        }
        // populate data in sorted date
        var dateseries = []
        var sigvalue_series = []
        var tmpsig = "";
        for (const keysig of  siglabel) {
            var sigcountarr = []
            for (const keydt of  datelabel) {
                var count = 0;
                for (const [key, value] of Object.entries(uniqplotdatesig)) {
                    if (keydt === value['date'] && keysig === value['signature']){
                        count = count + 1;
                        tmpsig = keysig;
                    }
                }
            sigcountarr.push(count)
            }
        sigvalue_series.push({'name': tmpsig, 'data': sigcountarr})
        }
        setConsumptionPerDay(sigvalue_series);
        setConsumptionDt(datelabel);
        //setLabelData(siglabel)
        setIsLoading(false);
      }
  useEffect(() => {
    fetchData()
  }, [])
  const series = consumptionpd;
  return (
    <React.Fragment>
    {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
        <ApexCharts
        options={themeOptions(theme, consumptionpdt)}
        series={series}
        type="line"
        height={350}
        width= {1290}
       // zoom = { enabled= false }
      />
    )}
    </React.Fragment>
  );
}

// ############################################################
function themeOptions(theme, consumptionpd) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      dashArray: 8,
      width: 2,
    },
    xaxis: {
      type: "datetime",
      categories: consumptionpd,
    },
    tooltip: {
      x: {
        format: "yyyy/MM/dd",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
