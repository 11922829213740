export const msalConfig = {
    auth: {
        clientId: '72f47e53-fa61-4a8d-88d8-f33e0d760cf0',
        authority: 'https://login.microsoftonline.com/8fa58a73-2ed8-4c41-8dc7-94bb010b4f94',
        redirectUri: '/',
    },
}

export const loginRequest = {
    scopes: ["User.Read"]
}

export const tokenRequest = {
    scopes: ["72f47e53-fa61-4a8d-88d8-f33e0d760cf0/.default"]
}
