import React , { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

import {get_access_txt, fetcher} from '../../../utils/Utils';
import { useMsal} from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';

export default function ApexDonutVTType() {
  var theme = useTheme();
  const [consumptionpd, setConsumptionPerDay] = useState({})
  const [seriesdata, setSeriesData] = useState([])
  const [labeldata, setLabelData] = useState([])
  const { accounts } = useMsal()
  let access_txt = get_access_txt(accounts, 'Customer');
  let access_txt_arr  = access_txt.split(",");
  const [isLoading, setIsLoading] = useState(false)
  let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/threatcontext/history"
  const fetchData = async () => {
    setIsLoading(true)
    let masterdata = [];
    let postData = {"customer": access_txt_arr[0]}
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic' },
        body: JSON.stringify(postData)
    };
    for (let i=0; i < access_txt_arr.length; i++) {
      postData = {"customer": access_txt_arr[i]};
      requestOptions['body'] = JSON.stringify(postData)
      let data = await fetcher(url, requestOptions)
      if (data && data.hasOwnProperty('history')){
      for (let j=0; j < data['history'].length; j++) {
      masterdata.push(data['history'][j]);
      }
      } else{
      }
      }
    let data = masterdata;
        setConsumptionPerDay(data)
        const countsByCs = [];
        data.forEach(({ killchain }) => {
        countsByCs[killchain] = (countsByCs[killchain] || 0) + 1;
        });
        const UniqArray = Object.entries(countsByCs)
        .map(([killchain, count]) => ({ killchain, count }))
        .sort((a, b) => b.count - a.count);

        var kcseries = [];
        var kclabel = [];
        for (var key in UniqArray) {
            kclabel.push(UniqArray[key]['killchain'])
            kcseries.push(UniqArray[key]['count'])
        }
        setSeriesData(kcseries)
        setLabelData(kclabel)
        setIsLoading(false);
        console.log("kcseries");
        console.log(kcseries);
        console.log(kclabel);
      //})
      //.catch(error => {
      //      console.error('There was an error!', error);
  //})
  //}
  }
  useEffect(() => {
    fetchData()
  }, [])
  const series = seriesdata;
  return (

    <React.Fragment>
    {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
        <ApexCharts
        options={themeOptions(theme, labeldata)}
        series={series}
        type="pie"
        height={350}
        width= {600}
      />
    )}
    </React.Fragment>
  );
}

// ############################################################
function themeOptions(theme, labeldata) {
  return {
    dataLabels: {
      enabled: false,
    },
    labels: labeldata,
    
    legend: {
      show: true,
    },
  };
}
