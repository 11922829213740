import React , { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import https from "https";


// components
import PageTitle from "../../components/PageTitle";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Controller, useForm } from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';

import {get_access_txt} from '../../utils/Utils';
import { useMsal} from "@azure/msal-react";


const styledeco = {
    //display: 'block',
    width: '20%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

const columns = [
{
  name: "customer",
  label: "Customer",
  options: {
   filter: false,
   sort: false,
  }
},
{
  name: "identifier",
  label: "Ip",
  options: {
   filter: false,
   sort: false,
  }
},
 {
  name: "assetInfo",
  label: "Asset Info",
  options: {
   filter: false,
   sort: false,
  }
 },
{
  name: "clientRoles",
  label: "Client Roles",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.join(", ")}
          </div>
        )
  }
},
{
  name: "serverRoles",
  label: "Server Roles",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value.join(", ")}
          </div>
        )
  }
},
{
  name: "assetValue",
  label: "Asset Value",
  options: {
   filter: false,
   sort: false,
  }
},
{
  name: "timestamp",
  label: "View",
  options: {
   filter: false,
   sort: false,
   customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <a href={"/#/app/assetcontextdetail?c="+tableMeta.rowData[0]+"&h="+tableMeta.rowData[1]}><VisibilityIcon/></a>
          </div>
        )
  }
}
];


export default function Tables() {
  let jsonassetcontext = '';
  let postData = {};
  const [assetcontext, setAssetContext] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { accounts } = useMsal()
  let access_txt = get_access_txt(accounts, 'Customer');
  let access_txt_arr  = access_txt.split(",");
  const { handleSubmit, control } = useForm();
  const onSubmit = (subdata: any) => fetchData(subdata);
  const httpsAgent = new https.Agent({
      rejectUnauthorized: false,
      requestCert: false,
      });

  const fetchData = (subdata) => {
    var selected_customer = document.getElementById("customerValue").options[document.getElementById("customerValue").selectedIndex].value;
    try {
        postData = {"customer": selected_customer, "ip": subdata.ipValue}
    } catch(error) {
        postData = {"customer": "j-auto-dev-forti", "ip": "10.1.11.99"}
    }
    setIsLoading(true)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic'},
        body: JSON.stringify(postData),
        agent: httpsAgent,
    };
    let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/assetcontext"
    fetch(url,
         requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        console.log(data)
        if (!response.ok) {
                // get error message from body or default to response status
                setAssetContext([])
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        return data 
      })
      .then(data => {
        setIsLoading(false)
        setAssetContext([data])
        jsonassetcontext = JSON.stringify([data] , null, 2);
      })
      .catch(error => {
            console.error('There was an error!', error);
            setIsLoading(false)
            setAssetContext([]);
            
  })
  }
  useEffect(() => {
    fetchData()
  }, [])
return (
    <>
      <PageTitle title="AssetContext View" />
      <form>
       <select style={styledeco} name="customerValue" id="customerValue">
      <option value="j-auto-dev-forti" selected>--Select Customer--</option>
    { access_txt_arr ?
    (access_txt_arr.map((dd) => {
             return <option value={dd} >{dd}</option>

    })) : null
    })
    </select>
      &nbsp;&nbsp;
      <Controller
        name={"ipValue"}
        control={control}
        render={
          
          ({ field: { onChange, value } }) => (
          <TextField onChange={onChange} value={value} label={"Ip"} />
          )
      }
      />
      <Button onClick={handleSubmit(onSubmit)} variant="contained">Search</Button>
      <br></br>
    </form> 
     <div></div> 
     <div></div> 
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <React.Fragment>
          {isLoading && assetcontext ? (
        <p><CircularProgress/></p>
      ) : ( 
          <MUIDataTable
            title="Asset Context Data"
            data={assetcontext}
            columns={columns}
            options={{
              selectableRows: false,
              filter: false,
              rowsPerPageOptions: false,
            }}
          />
    )}
    </React.Fragment>
        </Grid>
      </Grid>
    </>
  );
}
