import React from "react";
import {
  Grid,
} from "@material-ui/core";

// components
import Charts from "../../pages/charts";

export default function Dashboard(props) {
  return (
  <>
   <Grid container spacing={4}>
        <Grid item xs={12}>
       <Charts />
       </Grid>
       </Grid>
       </> 
       );

}
