import React , { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export default function Tables() {
  const [asdetails, setADetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const search = useLocation().search;
  const c = new URLSearchParams(search).get("c");
  const h = new URLSearchParams(search).get("h");
  var postData = {};
  postData = {"customer": c, "ip": h}
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic'},
        body: JSON.stringify(postData)
    };
    const fetchData = () => {
    setIsLoading(true)
    console.log(requestOptions);
    let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/assetcontext"
    console.log(url)
    fetch(url,
         requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        //console.log(data)
        if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        return data
      })
      .then(data => {
        console.log(data)
        setIsLoading(false)
        setADetails(data)
      })
      .catch(error => {
            console.error('There was an error!', error);
            setADetails([]);

  })
  }
  useEffect(() => {
    fetchData()
  }, [])
return (
          <React.Fragment>
    {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
         <div><a href="/#/app/assetcontext">Back</a><pre>{JSON.stringify(asdetails , null, 2)}</pre></div> 
    )}
    </React.Fragment>
  );

}
