import React , { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

export default function Tables() {
  const [thdetails, setTDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const search = useLocation().search;
  const ts = new URLSearchParams(search).get("t");
  const c = new URLSearchParams(search).get("c");
  var postData = {};
  postData = {"customer": c}
  const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer magic'},
        body: JSON.stringify(postData)
    };
    const fetchData = () => {
    setIsLoading(true)
    console.log(requestOptions);
    let url = process.env.REACT_APP_BOON_API_URL+"api/boon/v1/threatcontext/history"
    console.log(url)
    fetch(url,
         requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        //console.log(data)
        if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
        return data
      })
      .then(data => {
        console.log(data)
        setIsLoading(false)
        for (const [key, value] of Object.entries(data.history)) {
            if (value['timestamp'] == ts){
                setTDetails(value)
            }

        }
        //setTDetails(data['history'])
      })
      .catch(error => {
            console.error('There was an error!', error);
            setTDetails([]);

  })
  }
  useEffect(() => {
    fetchData()
  }, [])
return (
          <React.Fragment>
    {isLoading ? (
        <p><CircularProgress/></p>
      ) : ( 
         <div><a href="/#/app/threatcontexthistory">Back</a><pre>{JSON.stringify(thdetails , null, 2)}</pre></div> 
    )}
    </React.Fragment>
  );

}
